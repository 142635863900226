import React from 'react'

const Loading = () => {
  return (
    <ul className='bullets'>
      <li></li>
      <li></li>
      <li></li>
    </ul>
  )
}

export default Loading